import { Node } from 'tiptap'
import { setBlockType, textblockTypeInputRule, toggleBlockType } from 'tiptap-commands'
import { stringToPascal } from './helpers';

export default class HeadingScroll extends Node {

  get name() {
    return 'heading'
  }

  get defaultOptions() {
    return {
      levels: [3,4],
    }
  }

  get schema() {
    return {
      attrs: {
        level: {
          default: 3,
        },
        annotationId: {
            default: ''
        }
      },
      content: 'inline*',
      group: 'block',
      defining: true,
      draggable: false,
      parseDOM: this.options.levels
        .map(level => ({
          tag: `h${level}`,
          getAttrs: dom => {
            return {
              level: level,
              annotationId: stringToPascal(dom.textContent, {condense: true})
            }
          }
        })),
      toDOM: node => {
        return [`h${node.attrs.level}`, {'id': node.attrs.annotationId}, 0]
      }
    }
  }

  commands({ type, schema }) {
    return attrs => toggleBlockType(type, schema.nodes.paragraph, attrs)
  }

  keys({ type }) {
    return this.options.levels.reduce((items, level) => ({
      ...items,
      ...{
        [`Shift-Ctrl-${level}`]: setBlockType(type, { level }),
      },
    }), {})
  }

  inputRules({ type }) {
    return this.options.levels.map(level => textblockTypeInputRule(
      new RegExp(`^(#{1,${level}})\\s$`),
      type,
      () => ({ level }),
    ))
  }

}