const stringToPascal = (str, options) => {
    if (typeof str !== 'string') throw new TypeError('expected a string');

    return str.trim()
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .replace(/\W/g, m => /[À-ž]/.test(m) ? m : '-')
        .replace(/^-+|-+$/g, '')
        .replace('á', 'a')
        .replace('é', 'e')
        .replace('í', 'i')
        .replace('ó', 'o')
        .replace('ú', 'u')
        .replace('ñ', 'n')
        .replace(/-{2,}/g, m => options && options.condense ? '-' : m)
        .toLowerCase();
};

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
};

const trimPath = (path) => {
    let trimmed = '/';
    
    if (path != trimmed) {
        trimmed = path.replace('/', '');
    }
    
    return trimmed;
}

export { stringToPascal, getBase64, trimPath };