<template>
  <div class="create-page">
    <!-- Page Content Header -->
    <a-page-header
      :ghost="false"
      :title="isEditing ? 'Edit Event' : 'Create Event'"
      @back="() => $router.go(-1)"
    >
      <template slot="extra">
        <a-button
          key="draft"
          @click="handleSubmit"
          data-submit-type="draft"
          :loading="submitting"
        >Save to Draft</a-button>
        <a-button
          key="publish"
          type="primary"
          @click="handleSubmit"
          data-submit-type="publish"
          :loading="submitting"
        >Publish</a-button>
      </template>
    </a-page-header>
    <!-- Page Content Form -->
    <a-form-model ref="ruleForm" :model="form" :rules="rules">
      <a-row type="flex" justify="space-between">
        <a-col :span="14">
          <h2>Page Content</h2>
          <a-form-model-item ref="title" prop="title">
            <a-input
              v-model="form.title"
              type="text"
              size="large"
              placeholder="Page title"
              @keyup="handleSlug"
              @blur="() => $refs.title.onFieldBlur()"
            />
          </a-form-model-item>

          <a-form-model-item ref="subtitle" prop="subtitle">
            <a-input v-model="form.subtitle" size="large" placeholder="Subtitle">
              <a-tooltip slot="suffix" title="Subtitle goes centered on the page">
                <a-icon type="info-circle" style="color: rgba(0,0,0,.45)" />
              </a-tooltip>
            </a-input>
          </a-form-model-item>

          <a-form-model-item ref="icon" prop="icon">
            <a-dropdown>
              <a-menu slot="overlay" @click="handleIconClick">
                <a-menu-item key="application.svg">
                  <img :src="require('../../assets/vision/application.svg')" width="32" /> Application
                </a-menu-item>
                <a-menu-item key="data.svg">
                  <img :src="require('../../assets/vision/data.svg')" width="32" /> Data
                </a-menu-item>
                <a-menu-item key="phone-call.svg">
                  <img :src="require('../../assets/vision/phone-call.svg')" width="32" /> Phone Call
                </a-menu-item>
                <a-menu-item key="refund.svg">
                  <img :src="require('../../assets/vision/refund.svg')" width="32" /> Refund
                </a-menu-item>
                <a-menu-item key="terms-and-conditions.svg">
                  <img :src="require('../../assets/vision/terms-and-conditions.svg')" width="32" /> Terms and Condition
                </a-menu-item>
              </a-menu>
              <a-button>
                {{ form.icon || 'Select icon' }}
                <a-icon type="down" />
              </a-button>
            </a-dropdown>
            <div v-if="form.icon">
              <img :src="require(`../../assets/vision/${form.icon}`)" width="32" />
            </div>
          </a-form-model-item>

          <a-form-model-item>
            <a-radio-group :value="form.hasBody + ''" button-style="solid" @change="toggleBody">
              <a-radio-button value="true">Body text</a-radio-button>
              <a-radio-button value="false">Static page</a-radio-button>
            </a-radio-group>
          </a-form-model-item>

          <div class="field" v-show="form.hasBody">
            <div class="editor">
              <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
                <div class="menubar">
                  <div class="toolbar">
                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.bold() }"
                      @click="commands.bold"
                    >
                      <img :src="require(`../../assets/icons/bold.svg`)" />
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.italic() }"
                      @click="commands.italic"
                    >
                      <img :src="require(`../../assets/icons/italic.svg`)" />
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.strike() }"
                      @click="commands.strike"
                    >
                      <img :src="require(`../../assets/icons/strike.svg`)" />
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.underline() }"
                      @click="commands.underline"
                    >
                      <img :src="require(`../../assets/icons/underline.svg`)" />
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.heading({ level: 1 }) }"
                      @click="commands.heading({ level: 1 })"
                    >
                      <i>H1</i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.heading({ level: 2 }) }"
                      @click="commands.heading({ level: 2 })"
                    >
                      <i>H2</i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.heading({ level: 3}) }"
                      @click="commands.heading({ level: 3 })"
                    >
                      <i>H3</i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.bullet_list() }"
                      @click="commands.bullet_list"
                    >
                      <img :src="require(`../../assets/icons/ul.svg`)" />
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.ordered_list() }"
                      @click="commands.ordered_list"
                    >
                      <img :src="require(`../../assets/icons/ol.svg`)" />
                    </button>

                    <button class="menubar__button" @click="showImagePrompt(commands.image)">
                      <img :src="require(`../../assets/icons/image.svg`)" />
                    </button>

                    <button
                      class="menubar__button"
                      @click="commands.createTable({rowsCount: 3, colsCount: 3, withHeaderRow: false })"
                    >
                      <img :src="require(`../../assets/icons/table.svg`)" />
                    </button>

                    <span v-if="isActive.table()">
                      <button class="menubar__button" @click="commands.deleteTable">
                        <img :src="require(`../../assets/icons/delete_table.svg`)" />
                      </button>

                      <button class="menubar__button" @click="commands.addColumnBefore">
                        <img :src="require(`../../assets/icons/add_col_before.svg`)" />
                      </button>

                      <button class="menubar__button" @click="commands.addColumnAfter">
                        <img :src="require(`../../assets/icons/add_col_after.svg`)" />
                      </button>

                      <button class="menubar__button" @click="commands.deleteColumn">
                        <img :src="require(`../../assets/icons/delete_col.svg`)" />
                      </button>

                      <button class="menubar__button" @click="commands.addRowBefore">
                        <img :src="require(`../../assets/icons/add_row_before.svg`)" />
                      </button>

                      <button class="menubar__button" @click="commands.addRowAfter">
                        <img :src="require(`../../assets/icons/add_row_after.svg`)" />
                      </button>

                      <button class="menubar__button" @click="commands.deleteRow">
                        <img :src="require(`../../assets/icons/delete_row.svg`)" />
                      </button>

                      <button class="menubar__button" @click="commands.toggleCellMerge">
                        <img :src="require(`../../assets/icons/combine_cells.svg`)" />
                      </button>
                    </span>
                  </div>
                </div>
              </editor-menu-bar>

              <editor-menu-bubble
                class="menububble"
                :editor="editor"
                @hide="hideLinkMenu"
                v-slot="{ commands, isActive, getMarkAttrs, menu }"
              >
                <div
                  class="menububble"
                  :class="{ 'is-active': menu.isActive }"
                  :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
                >
                  <form
                    class="menububble__form"
                    v-if="linkMenuIsActive"
                    @submit.prevent="setLinkUrl(commands.link, linkUrl)"
                  >
                    <input
                      class="menububble__input"
                      type="text"
                      v-model="linkUrl"
                      placeholder="https://"
                      ref="linkInput"
                      @keydown.esc="hideLinkMenu"
                    />
                    <button
                      class="menububble__button"
                      @click="setLinkUrl(commands.link, null)"
                      type="button"
                    >
                      <span>X</span>
                    </button>
                  </form>

                  <template v-else>
                    <button
                      class="menububble__button"
                      @click="showLinkMenu(getMarkAttrs('link'))"
                      :class="{ 'is-active': isActive.link() }"
                    >
                      <span>{{ isActive.link() ? 'Update Link' : 'Add Link'}}</span>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <title>hyperlink-2</title>
                        <path
                          d="M12.406,14.905a1,1,0,0,0-.543,1.307,1,1,0,0,1-.217,1.09L8.818,20.131a2,2,0,0,1-2.828,0L3.868,18.01a2,2,0,0,1,0-2.829L6.7,12.353a1.013,1.013,0,0,1,1.091-.217,1,1,0,0,0,.763-1.849,3.034,3.034,0,0,0-3.268.652L2.454,13.767a4.006,4.006,0,0,0,0,5.657l2.122,2.121a4,4,0,0,0,5.656,0l2.829-2.828a3.008,3.008,0,0,0,.651-3.27A1,1,0,0,0,12.406,14.905Z"
                        />
                        <path
                          d="M7.757,16.241a1.011,1.011,0,0,0,1.414,0L16.95,8.463a1,1,0,0,0-1.414-1.414L7.757,14.827A1,1,0,0,0,7.757,16.241Z"
                        />
                        <path
                          d="M21.546,4.574,19.425,2.453a4.006,4.006,0,0,0-5.657,0L10.939,5.281a3.006,3.006,0,0,0-.651,3.269,1,1,0,1,0,1.849-.764A1,1,0,0,1,12.354,6.7l2.828-2.828a2,2,0,0,1,2.829,0l2.121,2.121a2,2,0,0,1,0,2.829L17.3,11.645a1.015,1.015,0,0,1-1.091.217,1,1,0,0,0-.765,1.849,3.026,3.026,0,0,0,3.27-.651l2.828-2.828A4.007,4.007,0,0,0,21.546,4.574Z"
                        />
                      </svg>
                    </button>
                  </template>
                </div>
              </editor-menu-bubble>

              <editor-content class="editor__content" :editor="editor" />
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <h2>Page Information</h2>

          <a-form-model-item ref="slug" prop="slug">
            <a-input
              v-model="form.slug"
              type="text"
              size="large"
              placeholder="url-page-name"
              @blur="() => $refs.slug.onFieldBlur()"
            >
              <a-icon slot="prefix" type="link" />
              <a-tooltip
                slot="suffix"
                title="The Slug is the page uri.  https://domain.com/{slug-goes-here}"
              >
                <a-icon type="info-circle" style="color: rgba(0,0,0,.45)" />
              </a-tooltip>
            </a-input>
          </a-form-model-item>

          <a-form-model-item ref="meta_keywords" prop="meta_keywords">
            <a-input
              v-model="form.meta_keywords"
              type="text"
              size="large"
              placeholder="eXIM, privacy policy."
              @blur="() => $refs.meta_keywords.onFieldBlur()"
            >
              <a-tooltip slot="suffix" title="Keywords must be separated by comma and lowercase">
                <a-icon type="info-circle" style="color: rgba(0,0,0,.45)" />
              </a-tooltip>
            </a-input>
          </a-form-model-item>

          <a-form-model-item ref="meta_description" prop="meta_description">
            <a-input
              v-model="form.meta_description"
              placeholder="meta description here."
              type="textarea"
              @blur="() => $refs.meta_description.onFieldBlur()"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>

<script>
import isEmpty from "lodash/isEmpty";
import { Editor, EditorContent, EditorMenuBar, EditorMenuBubble } from "tiptap";
import { mapState } from "vuex";
import { stringToPascal } from "../../utils/helpers";
import HeadingScroll from "../../utils/headingScroll";

import {
  Blockquote,
  Heading,
  OrderedList,
  BulletList,
  ListItem,
  Bold,
  Italic,
  Link,
  Table,
  TableHeader,
  TableCell,
  TableRow,
  Strike,
  Underline,
  History,
  Image,
} from "tiptap-extensions";

const defaultEditor = `<h3 id="scrolling-head">Scrolling Head</h3>
          <p>Tables come with some useful commands like adding, removing or merging rows and columns. Navigate with <code>tab</code> or arrow keys. Resizing is also supported.</p>
          <table>
            <tr>
              <th colspan="3" data-colwidth="100,0,0">Wide header</th>
            </tr>
            <tr>
              <td>One</td>
              <td>Two</td>
              <td>Three</td>
            </tr>
            <tr>
              <td>Four</td>
              <td>Five</td>
              <td>Six</td>
            </tr>
          </table>`;

export default {
  name: "LegalesForm",
  params: ["node"],
  components: {
    EditorContent,
    EditorMenuBar,
    EditorMenuBubble,
  },
  data() {
    return {
      rules: {
        title: [{ required: true, message: "Please enter a page title" }],
        slug: [
          { required: true, message: "Slug is required." },
          {
            pattern: /^(?:\/|([A-Za-z0-9]+(?:-[A-Za-z0-9]+)*))/,
            message: "Slug must use dash and alphanumeric characters.",
          },
        ],
      },
      form: {
        title: "",
        subtitle: "",
        icon: "",
        slug: "",
        hasBody: true,
        body: "",
        meta_keywords: "",
        meta_description: "",
        order: 0
      },
      editor: new Editor({
        extensions: [
          new Blockquote(),
          new BulletList(),
          new Heading({ levels: [1, 2] }),
          new HeadingScroll({ levels: [3, 4] }),
          new ListItem(),
          new OrderedList(),
          new Link(),
          new Bold(),
          new Image(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
          new Table({
            resizable: true,
          }),
          new TableHeader(),
          new TableCell(),
          new TableRow(),
        ],
        content: null,
      }),
      linkUrl: null,
      linkMenuIsActive: false,
      submitting: false,
      isEditing: false,
    };
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  mounted() {
    if (!isEmpty(this.legalesEdit)) {
      this.form.title = this.legalesEdit.title;
      this.form.subtitle = this.legalesEdit.subtitle;
      this.form.slug = this.legalesEdit.slug;
      this.form.hasBody = this.legalesEdit.hasBody;
      this.body = this.legalesEdit.body || "";
      this.form.meta_keywords = this.legalesEdit.meta_keywords;
      this.form.meta_description = this.legalesEdit.meta_description;
      this.form.order = this.legalesEdit.order;
      this.form.icon = this.legalesEdit.icon;
      this.isEditing = true;
      if (this.form.hasBody && !isEmpty(this.legalesEdit.body)) {
        this.editor.setContent(this.legalesEdit.body);
      }
    } else {
      this.editor.setContent(defaultEditor);
    }
  },
  computed: {
    ...mapState(["legales"]),
    legalesEdit() {
      if (isEmpty(this.$route.params.id)) {
        return null;
      }
      return this.$store.getters.getLegalesById(this.$route.params.id);
    },
  },
  methods: {
    handleSubmit(e) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.submitting = true;

          const data = {
            title: this.form.title,
            subtitle: this.form.subtitle,
            slug: this.form.slug,
            static: !this.form.hasBody,
            meta_keywords: this.form.meta_keywords,
            meta_description: this.form.meta_description,
            hasBody: this.form.hasBody,
            icon: this.form.icon,
            menu: []
          };

          if (e.target.dataset.submitType === "publish") {
            Object.assign(data, { status: "published" });
          } else {
            Object.assign(data, { status: "draft" });
          }

          if (!this.form.hasBody) {
            Object.assign(data, { body: "" });
            this.form.body = "";
          } else {
            const json = this.editor.getJSON();
            const menu = [];
            for (let item of json.content) {
              if (item.type === "heading" && item.attrs.level == 3) {
                if (item.content[0].text) {
                  menu.push({
                    scrollTo: stringToPascal(item.content[0].text, {
                      condense: true,
                    }),
                    text: item.content[0].text,
                  });
                }
              }
            }

            Object.assign(data, {
              menu: menu,
              body: this.editor.getHTML(),
            });
          }

          if (this.isEditing) {
            Object.assign(data, {
              order: this.form.order,
              id: this.legalesEdit.id,
            });
            this.$store
              .dispatch("updateLegales", data)
              .then(() => {
                this.submitting = false;
              })
              .catch((error) => {
                this.submitting = false;
                console.log(error);
              });
          } else {
            Object.assign(data, { order: this.legales.legales.length });
            let _router = this.$router;

            this.$store
              .dispatch("createLegales", data)
              .then(() => {
                this.submitting = false;
                _router.push("/dashboard/legales");
              })
              .catch((error) => {
                this.submitting = false;
                console.log(error);
              });
          }
        } else {
          return;
        }
      });
    },
    handleSlug() {
      this.form.slug = stringToPascal(this.form.title);
    },
    showImagePrompt(command) {
      const src = prompt("Enter the url of your image here");
      if (src !== null) {
        command({ src });
      }
    },
    showLinkMenu(attrs) {
      this.linkUrl = attrs.href;
      this.linkMenuIsActive = true;
      this.$nextTick(() => {
        this.$refs.linkInput.focus();
      });
    },
    hideLinkMenu() {
      this.linkUrl = null;
      this.linkMenuIsActive = false;
    },
    setLinkUrl(command, url) {
      command({ href: url });
      this.hideLinkMenu();
    },
    toggleBody() {
      this.form.hasBody = !this.form.hasBody;
    },
    handleIconClick(e) {
      this.form.icon = e.key;
    },
  },
};
</script>

<style lang="less">
@purple: #692f91;
@lightblue: #1890ff;

.create-page {
  .ant-form {
    padding: 2rem;
  }

  .field {
    margin-bottom: 1rem;
    .slug {
      display: block;
      margin-top: 1rem;
    }
  }
  .editor {
    background: white;
    border: 1px solid #d9d9d9;
    border-radius: 5px;

    .menubar {
      margin-bottom: 1rem;
      padding: 0.5rem;

      &__button {
        background: white;
        border: 0;
        border-radius: 2px;
        margin: 0 3px;
        padding: 2px 10px;

        &:hover,
        &.is-active {
          background: #f0f0fb;
        }

        svg {
          fill: white;
          margin-left: 5px;
        }

        img,
        svg {
          width: 14px;
        }
      }
    }

    .menububble {
      background: black;
      border-radius: 5px;
      display: flex;
      opacity: 0;
      padding: 0.3rem;
      position: absolute;
      transform: translateX(-50%);
      transition: opacity 0.2s, visibility 0.2s;
      visibility: hidden;
      z-index: 20;

      &.is-active {
        opacity: 1;
        visibility: visible;
      }

      &__button {
        background: none;
        border: 0;
        color: white;
        svg {
          fill: white;
          margin-left: 0.5rem;
          width: 14px;
        }
      }
    }

    &__content {
      padding: 1rem;

      h1,
      h2,
      h3 {
        color: #444;
        font-weight: 600;
      }

      a {
        color: @lightblue;
        text-decoration: underline;
      }

      table,
      th,
      td {
        border: 1px solid;
      }
    }
    .avatar-uploader > .ant-upload {
      width: 128px;
      height: 128px;
    }
    .ant-upload-select-picture-card i {
      font-size: 32px;
      color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
      margin-top: 8px;
      color: #666;
    }
  }
}
</style>
